<template>
    <div class="h-full">
        <div class="px-8 py-8 lg:px-16 lg:py-16" v-if="successful">
            <svg
                class="w-16 h-16 mx-auto"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 477.867 477.867"
                :fill="link.brand_color"
                xml:space="preserve">
                <g><g><path d="M238.933,0C106.974,0,0,106.974,0,238.933s106.974,238.933,238.933,238.933s238.933-106.974,238.933-238.933
			C477.726,107.033,370.834,0.141,238.933,0z M238.933,443.733c-113.108,0-204.8-91.692-204.8-204.8s91.692-204.8,204.8-204.8
			s204.8,91.692,204.8,204.8C443.611,351.991,351.991,443.611,238.933,443.733z"/></g></g><g><g><path d="M370.046,141.534c-6.614-6.388-17.099-6.388-23.712,0v0L187.733,300.134l-56.201-56.201
			c-6.548-6.78-17.353-6.967-24.132-0.419c-6.78,6.548-6.967,17.353-0.419,24.132c0.137,0.142,0.277,0.282,0.419,0.419
			l68.267,68.267c6.664,6.663,17.468,6.663,24.132,0l170.667-170.667C377.014,158.886,376.826,148.082,370.046,141.534z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
</g><g></g></svg>

            <h1 class="text-2xl text-center font-medium text-gray-800 my-8">Payment Successful</h1>

            <div class="mb-4 text-center">
                <a
                    v-if="isReceiptReady"
                    :href="receipt"
                    class='w-full hover:bg-blue-700 text-xl text-white py-3 px-5 rounded cursor-pointer'
                    :style="`background: ${link.brand_color}`"
                >Download Receipt</a>

                <p v-else :style="`color: ${link.brand_color}`">
                    Thanks for the payment. <br>
                    We're preparing a payment receipt for you & it'll be emailed to you when ready.
                </p>
            </div>
        </div>

        <div class="px-8 lg:px-16 py-8 relative" v-else>
            <div class="absolute bg-white flex h-full inset-0 z-10" v-if="isLoading">
                <svg
                    class="w-24 h-24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="margin: auto; background: none; display: block; shape-rendering: auto;"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" :stroke="link.brand_color" stroke-width="2" r="24" stroke-dasharray="113.09733552923255 39.69911184307752">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                </svg>
            </div>

            <h1 class="text-xl font-medium capitalize text-center text-gray-800 mb-4">Enter payment details</h1>

            <div class="mb-4">
                <input
                    id="name"
                    name="name"
                    type="text"
                    v-model="name"
                    autocomplete="name"
                    placeholder="Your name"
                    class="appearance-none border border-gray-400 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none placeholder-gray-400"
                >
                <div class="text-sm text-red-600 mt-1" v-if="errors.name">{{ errors.name[0] }}</div>
            </div>

            <div class="mb-4">
                <input
                    id="email"
                    name="email"
                    type="email"
                    v-model="email"
                    autocomplete="email"
                    placeholder="Your email address"
                    class="appearance-none border border-gray-400 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none placeholder-gray-400"
                >
                <div class="text-sm text-red-600 mt-1" v-if="errors.email">{{ errors.email[0] }}</div>
            </div>

            <div class="mb-4">
                <div class="border border-gray-400 rounded">
                    <div id="card-element"></div>
                </div>
                <div class="text-xs text-red-600 mt-1" v-if="cardError">{{ cardError }}</div>
            </div>

            <div class="mb-8">
                <input
                    type="text"
                    id="cardholder-name"
                    autocomplete="cc-name"
                    name="cardholder_name"
                    v-model="cardHolderName"
                    placeholder="Name on card"
                    class="appearance-none border border-gray-400 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none placeholder-gray-400"
                >
            </div>

            <button
                class='w-full hover:bg-blue-700 font-semibold text-xl text-white p-3 rounded'
                :class="{'cursor-not-allowed' : ! isReady}"
                :style="`background: ${link.brand_color}`"
                @click='pay'
                :disabled='!isReady'
            >{{ link.trial_days ? `Start ${link.trial_days} Days Trial`  : 'Pay Now' }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            link: {type: Object, required: true},
        },

        computed: {
            isReady() {
                if (this.isLoading) {
                    return false;
                }

                return this.name && this.email && this.cardHolderName && this.complete;
            },

            description() {
                if (this.showFullDescription) {
                    return this.link.description;
                }

                return this.link.description.substring(0, 200) + '...';
            }
        },

        data() {
            return {
                card: null,
                name: null,
                errors: {},
                email: null,
                stripe: null,
                receipt: null,
                elements: null,
                complete: false,
                cardError: null,
                isLoading: false,
                successful: false,
                cardHolderName: null,
                isReceiptReady: false,
            }
        },

        mounted() {
            this.init();
        },

        methods: {
            init() {
                this.stripe = Stripe(this.link.platform_key, {stripeAccount: this.link.stripe_account});
                this.elements = this.stripe.elements({
                    fonts: [{
                        cssSrc: 'https://fonts.googleapis.com/css?family=PT+Sans&display=swap',
                    }],
                });
                this.card = this.elements.create("card", {
                    style: {
                        base: {
                            fontFamily: "'PT Sans', sans-serif",
                            color: '#4a5568',
                            lineHeight: '1.25',
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                                color: '#aab7c4'
                            }
                        }
                    }
                });

                _.delay(() => {
                    this.card.mount("#card-element");
                    this.card.addEventListener('change', e => {
                        this.complete = e.complete;
                        this.cardError = e.error ? e.error.message : null;
                    });
                }, 10);
            },
            pay() {
                this.isLoading = true;
                this.errors = {};
                if (this.link.frequency === 'single' || this.link.frequency === 'open') {
                    this.processSinglePayment();
                } else {
                    this.processRecurringPayment();
                }
            },

            processSinglePayment() {
                axios.post('/api/links/' + this.link.slug + '/payments', {
                    name: this.name,
                    email: this.email,
                    amount: this.link.amount,
                })
                    .then(this.confirmCardPaymentForSingle)
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                    });
            },

            confirmCardPaymentForSingle({data}) {
                this.receipt = data.receipt;
                this.stripe
                    .confirmCardPayment(data.client_secret, {
                        payment_method: {
                            card: this.card,
                            billing_details: {
                                name: this.cardHolderName,
                            }
                        }
                    }).then(response => {
                    if (response.error) {
                        this.cardError = response.error.message;
                    } else {
                        this.handleSuccessAction();
                    }
                    this.isLoading = false;
                });
            },

            processRecurringPayment() {
                this.stripe
                    .createPaymentMethod({
                        type: 'card',
                        card: this.card,
                        billing_details: {
                            name: this.cardHolderName,
                        },
                    })
                    .then(response => {
                        if (response.error) {
                            this.cardError = response.error.message;
                            this.isLoading = false;
                        } else {
                            this.createSubscription(response);
                        }
                    });
            },

            createSubscription(response) {
                axios.post('/api/links/' + this.link.slug + '/subscriptions', {
                    name: this.name,
                    email: this.email,
                    payment_method: response.paymentMethod.id,
                }).then(this.confirmCardPaymentForSubscription)
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                    });
            },

            confirmCardPaymentForSubscription({data}) {
                this.receipt = data.receipt;
                if (data.client_secret) {
                    this.stripe
                        .confirmCardPayment(data.client_secret)
                        .then(result => {
                            if (result.error) {
                                this.cardError = result.error.message;
                                this.isLoading = false;
                            } else {
                                this.handleSuccessAction();
                            }
                        });
                } else {
                    this.handleSuccessAction();
                }
            },

            handleSuccessAction() {
                if (this.link.redirect_url) {
                    window.location = this.link.redirect_url;
                } else {
                    this.checkIfReceiptIsReady();
                    this.successful = true;
                    this.isLoading = false;
                }
            },

            checkIfReceiptIsReady() {
                axios.get(this.receipt + '/ready').then(({data}) => {
                    if (data.is_ready) {
                        this.isReceiptReady = true;
                    } else {
                        _.delay(() => {
                            this.checkIfReceiptIsReady();
                        }, 3000);
                    }
                });
            }
        },

        watch: {
            errors(errors) {
                this.$emit('updateErrors', errors);
            }
        }
    }
</script>

<style>
    .stripe-card.complete {
        border-color: green;
    }

    .StripeElement {
        border-radius: 4px;
        box-sizing: border-box;
        height: 44px;
        padding: 0.75rem 1rem;
        border: 1px solid transparent;
        background-color: white;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .InputElement {
        font-family: "PT Sans", sans-serif;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
