<template>
    <div class="flex">
        <svg viewBox="0 0 26 22" class="w-8 h-full self-center" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 19.2793C0 20.5249 0.970486 21.5355 2.16667 21.5355H23.8333C25.0295 21.5355 26 20.5249 26 19.2793V11.0067H0V19.2793ZM8.66667 16.0831C8.66667 15.7729 8.91042 15.519 9.20833 15.519H15.3472C15.6451 15.519 15.8889 15.7729 15.8889 16.0831V17.9632C15.8889 18.2734 15.6451 18.5273 15.3472 18.5273H9.20833C8.91042 18.5273 8.66667 18.2734 8.66667 17.9632V16.0831ZM2.88889 16.0831C2.88889 15.7729 3.13264 15.519 3.43056 15.519H6.68056C6.97847 15.519 7.22222 15.7729 7.22222 16.0831V17.9632C7.22222 18.2734 6.97847 18.5273 6.68056 18.5273H3.43056C3.13264 18.5273 2.88889 18.2734 2.88889 17.9632V16.0831ZM26 2.73415V4.99031H0V2.73415C0 1.48857 0.970486 0.477997 2.16667 0.477997H23.8333C25.0295 0.477997 26 1.48857 26 2.73415Z"
                fill="currentColor"/>
        </svg>

        <div v-if="link.frequency === 'open'" class="ml-5">
            <div><label for="amount">Enter Amount</label></div>
            <div class="font-bold text-xl hidden md:block" :style="style">
                <div class="relative">
                    <span class="absolute pin px-4 py-1 text-2xl">{{ link.currency }}</span>
                    <input
                        id="amount"
                        v-model="link.amount"
                        type="number"
                        class="appearance-none border border-60 focus:outline-none focus:shadow-outline leading-tight pl-8 py-2 rounded shadow w-1/2"
                        :class="{'border-red-300' : errorMessage}"
                    >
                </div>

                <div class="text-sm text-red-600 mt-1" v-if="errorMessage">{{ errorMessage }}</div>
            </div>

            <div class="font-bold text-xl md:hidden">
                <div class="relative">
                    <span class="absolute pin px-4 py-1 text-2xl">{{ link.currency }}</span>
                    <input
                        id="amount"
                        v-model="link.amount"
                        type="number"
                        class="appearance-none border border-60 focus:outline-none focus:shadow-outline leading-tight pl-8 py-2 rounded shadow w-2/3"
                        :class="{'border-red-300' : errorMessage}"
                    >
                </div>

                <div class="text-sm text-red-600 mt-1" v-if="errorMessage">{{ errorMessage }}</div>
            </div>
        </div>

        <div class="ml-5 leading-tight" v-else>
            <div>Amount</div>
            <div class="font-bold text-xl">
                <span v-html="link.currency + link.total"></span>
                <span v-if="link.trial_days" class="text-sm font-normal">After {{ link.trial_days }} days</span>

                <p v-if="link.frequency !== 'single' || link.frequency !== 'open'" class="font-normal text-sm leading-none">
                    <template v-if="link.setup_amount">
                        {{ link.currency }}{{ link.amount_with_tax }}{{ frequency }} afterward
                    </template>
                </p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            link: {type: Object, required: true},
            errors: {type: Object, required: true},
            color: {type: String, required: false},
        },

        computed: {
            frequency() {
                if (this.link.frequency === 'single' || this.link.frequency === 'open') {
                    return '';
                }

                if (this.link.frequency === 'daily') {
                    return '/Day';
                }

                if (this.link.frequency === 'weekly') {
                    return '/Week';
                }

                if (this.link.frequency === 'monthly') {
                    return '/Month';
                }

                if (this.link.frequency === 'yearly') {
                    return '/Year';
                }

                return `charges ${this.link.frequency}`;
            },

            style() {
              if(this.color) {
                  return `color: ${this.color}`;
              }

              return '';
            },

            errorMessage() {
                return this.errors.amount ? this.errors.amount[0] : '';
            }
        }
    }
</script>
