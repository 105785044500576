<template>
    <div class="flex" v-if="link.expiration_count">
        <svg width="23" height="22" class="w-8 h-full self-center" viewBox="0 0 23 22" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.3839 7.55723H0.616071C0.275846 7.55723 0 7.33625 0 7.0637V5.5831C0 4.4928 1.10328 3.60896 2.46429 3.60896H4.92857V1.47031C4.92857 1.19776 5.20442 0.976776 5.54464 0.976776H7.59821C7.93844 0.976776 8.21429 1.19776 8.21429 1.47031V3.60896H14.7857V1.47031C14.7857 1.19776 15.0616 0.976776 15.4018 0.976776H17.4554C17.7956 0.976776 18.0714 1.19776 18.0714 1.47031V3.60896H20.5357C21.8967 3.60896 23 4.4928 23 5.5831V7.0637C23 7.33625 22.7242 7.55723 22.3839 7.55723ZM0.616071 8.87333H22.3839C22.7242 8.87333 23 9.09431 23 9.36686V20.0601C23 21.1504 21.8967 22.0342 20.5357 22.0342H2.46429C1.10328 22.0342 0 21.1504 0 20.0601V9.36686C0 9.09431 0.275846 8.87333 0.616071 8.87333ZM17.7272 12.8194L16.2811 11.6515C16.0415 11.458 15.6514 11.4567 15.4098 11.6487L9.96598 15.9747L7.6054 14.0683C7.3658 13.8748 6.97567 13.8735 6.73412 14.0655L5.27624 15.2241C5.03469 15.416 5.0331 15.7285 5.27275 15.922L9.51343 19.3467C9.75303 19.5402 10.1431 19.5415 10.3847 19.3495L17.7238 13.5174C17.9653 13.3254 17.9669 13.0129 17.7272 12.8194Z"
                fill="currentColor"/>
        </svg>

        <div class="ml-5 leading-tight">
            <div>No. of payments</div>
            <div>
                <span class="text-xl capitalize font-bold" v-html="link.expiration_count"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            link: {type: Object, required: true},
        }
    }
</script>
