<template>
    <div class="lg:flex" v-if="link.name">
        <div class="w-full md:hidden relative lg:w-1/2 px-8 md:pt-8 lg:pl-8 lg:pr-16 lg:border-solid lg:border-r">
            <div class="px-4 pt-8">
                <div class="pb-4">
                    <h1 class="text-2xl text-center">{{ link.name }}</h1>
                </div>

                <checkout-description
                    :description="link.description"
                    container-class="text-sm text-justify"
                    more-class="underline"
                ></checkout-description>

                <div class="border-t border-gray-300 mt-4 w-48 mx-auto"></div>

                <div class="px-8">
                    <div class="w-full mb-8">
                        <div class="mt-8">
                            <checkout-amount :link="link" :errors="errors"></checkout-amount>
                        </div>

                        <div class="mt-8" v-if="link.setup_amount">
                            <checkout-setup-amount :link="link"></checkout-setup-amount>
                        </div>

                        <div class="mt-8">
                            <checkout-type :link="link"></checkout-type>
                        </div>

                        <div class="mt-8" v-if="link.expiration_count">
                            <checkout-payment-count :link="link"></checkout-payment-count>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-t border-gray-300 mt-4 w-48 mx-auto md:hidden"></div>

        <div
            class="hidden md:block w-full relative lg:w-1/2 px-8 pt-8 lg:pl-8 lg:pr-16 lg:border-solid lg:border-r">

            <div class="pl-16 py-8">
                <div class="pb-4">
                    <h2 class="text-3xl">Order Details</h2>
                </div>

                <div class="w-full mb-8">
                    <div class="mt-8">
                        <checkout-amount :errors="errors" :link="link"></checkout-amount>
                    </div>

                    <div class="mt-8" v-if="link.setup_amount">
                        <checkout-setup-amount :link="link"></checkout-setup-amount>
                    </div>

                    <div class="mt-8" v-if="link.tax">
                        <checkout-tax :link="link"></checkout-tax>
                    </div>

                    <div class="mt-8">
                        <checkout-type :link="link"></checkout-type>
                    </div>

                    <div class="mt-8">
                        <checkout-payment-count :link="link"></checkout-payment-count>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full lg:w-1/2">
            <payment-form v-if="link" :link="link" @updateErrors="updateErrors"></payment-form>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            slug: {type: String, required: true},
        },

        data() {
            return {
                link: {},
                errors: {},
                isLoading: false,
            }
        },

        mounted() {
            axios.get('/api/links/' + this.slug).then(({data}) => {
                this.link = data.data;
            }).catch(error => {
                this.errors = error.response.data.errors;
                this.isLoading = false;
            });
        },

        methods: {
            updateErrors(errors) {
                this.errors = errors;
            }
        }
    }
</script>

<style>
    .trig-right {
        transform: rotate(45deg);
        width: 24px;
        right: -12px;
        height: 24px;
        background: rgb(255, 255, 255);
        position: absolute;
        top: 50%;
        margin-top: -12px;
    }
</style>
