<template>
    <div class="flex">
        <svg viewBox="0 0 25 27" class="w-8 h-full self-center" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3214 0H2.67857C1.25 0 0 1.18125 0 2.53125V24.4688C0 25.8188 1.25 27 2.67857 27H22.3214C23.75 27 25 25.8188 25 24.4688V2.53125C25 1.18125 23.75 0 22.3214 0ZM7.14286 22.95C7.14286 23.2875 6.78571 23.625 6.42857 23.625H4.28571C3.92857 23.625 3.57143 23.2875 3.57143 22.95V20.925C3.57143 20.5875 3.92857 20.25 4.28571 20.25H6.42857C6.78571 20.25 7.14286 20.5875 7.14286 20.925V22.95ZM7.14286 16.2C7.14286 16.5375 6.78571 16.875 6.42857 16.875H4.28571C3.92857 16.875 3.57143 16.5375 3.57143 16.2V14.175C3.57143 13.8375 3.92857 13.5 4.28571 13.5H6.42857C6.78571 13.5 7.14286 13.8375 7.14286 14.175V16.2ZM14.2857 22.95C14.2857 23.2875 13.9286 23.625 13.5714 23.625H11.4286C11.0714 23.625 10.7143 23.2875 10.7143 22.95V20.925C10.7143 20.5875 11.0714 20.25 11.4286 20.25H13.5714C13.9286 20.25 14.2857 20.5875 14.2857 20.925V22.95ZM14.2857 16.2C14.2857 16.5375 13.9286 16.875 13.5714 16.875H11.4286C11.0714 16.875 10.7143 16.5375 10.7143 16.2V14.175C10.7143 13.8375 11.0714 13.5 11.4286 13.5H13.5714C13.9286 13.5 14.2857 13.8375 14.2857 14.175V16.2ZM21.4286 22.95C21.4286 23.2875 21.0714 23.625 20.7143 23.625H18.5714C18.2143 23.625 17.8571 23.2875 17.8571 22.95V14.175C17.8571 13.8375 18.2143 13.5 18.5714 13.5H20.7143C21.0714 13.5 21.4286 13.8375 21.4286 14.175V22.95ZM21.4286 9.45C21.4286 9.7875 21.0714 10.125 20.7143 10.125H4.28571C3.92857 10.125 3.57143 9.7875 3.57143 9.45V4.05C3.57143 3.7125 3.92857 3.375 4.28571 3.375H20.7143C21.0714 3.375 21.4286 3.7125 21.4286 4.05V9.45Z" fill="currentColor"/>
        </svg>

        <div class="ml-5">
            <div class="text-lg leading-tight">
                <p v-if="link.setup_amount">
                    Initial Payment: <span class="font-bold">{{ `${link.currency}${link.setup_amount}` }}</span>
                </p>

                <p v-if="link.frequency !== 'single' || link.frequency !== 'open'">
                    <span class="capitalize">{{ link.frequency }}</span>: <span class="font-bold">{{ link.currency }}{{ link.amount }}</span>
                </p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            link: {type: Object, required: true},
        },

        computed: {
            frequency() {
                if (this.link.frequency === 'single' || this.link.frequency === 'open') {
                    return '';
                }

                if (this.link.frequency === 'daily') {
                    return '/Day';
                }

                if (this.link.frequency === 'weekly') {
                    return '/Week';
                }

                if (this.link.frequency === 'monthly') {
                    return '/Month';
                }

                if (this.link.frequency === 'yearly') {
                    return '/Year';
                }

                return `charges ${this.link.frequency}`;
            }
        }
    }
</script>
