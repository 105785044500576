<template>
    <div
        class="modal select-none fixed inset-0 z-50 overflow-x-hidden overflow-y-auto flex"
    >
        <div class="relative mx-auto flex justify-center z-20 items-center">
            <div v-on-clickaway="close"><slot /></div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [clickaway],

    props: {
        classWhitelist: [Array, String],
    },

    created() {
        document.addEventListener('keydown', this.handleEscape)
        document.body.classList.add('overflow-hidden')

        const modalBg = document.createElement('div')
        modalBg.classList = 'fixed inset-0 bg-gray-800 z-20 opacity-75'

        this.modalBg = modalBg

        document.body.appendChild(this.modalBg)
    },

    destroyed() {
        document.removeEventListener('keydown', this.handleEscape)
        document.body.classList.remove('overflow-hidden')
        document.body.removeChild(this.modalBg)
    },

    data: () => ({ modalBg: null }),

    methods: {
        handleEscape(e) {
            e.stopPropagation()

            if (e.keyCode == 27) {
                this.close(e)
            }
        },

        close(e) {
            if (!e.isTrusted) return

            this.$emit('modal-close', e)
        },
    },
}
</script>
