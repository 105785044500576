<template>
    <div class="text-sm" :class="containerClass" v-if="description">
        {{ descriptionText }}
        <span v-if="description.length > descriptionLimit" @click="showFullDescription = ! showFullDescription" class="font-bold text-center cursor-pointer" :class="moreClass">
            {{ showFullDescription ? 'Show Less' : 'Read More' }}
        </span>
    </div>
</template>


<script>
    export default {
        props: {
            description: {
                validator: function (value) {
                    return typeof value === "string" || value == null;
                },
                required: true
            },
            containerClass: {type: String, required: false},
            moreClass: {type: String, required: false},
            limit: {type: Number, required: false},
        },

        data() {
            return {
                showFullDescription: false,
                descriptionLimit: this.limit ? this.limit : 150,
            };
        },

        computed: {
            descriptionText() {
                if (this.showFullDescription) {
                    return this.description;
                }

                return this.description.substring(0, this.descriptionLimit);
            }
        }
    }
</script>
