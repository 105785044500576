<template>
    <div class="flex">
        <svg v-if="link.frequency === 'open'" width="22" height="22" class="w-8 h-full self-center" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 19.5C15.9371 19.5 19.9375 15.1359 19.9375 9.75C19.9375 4.36406 15.9371 0 11 0C6.06289 0 2.0625 4.36406 2.0625 9.75C2.0625 15.1359 6.06289 19.5 11 19.5ZM10.0461 4.56563V3.77812C10.0461 3.34687 10.3641 3 10.7594 3H11.2363C11.6316 3 11.9496 3.34687 11.9496 3.77812V4.575C12.6156 4.6125 13.2602 4.86094 13.7973 5.29688C14.0379 5.48906 14.0637 5.87344 13.8488 6.09844L13.1484 6.825C12.9852 6.99844 12.7402 7.00313 12.5469 6.87188C12.3148 6.7125 12.057 6.63281 11.782 6.63281H10.1105C9.72383 6.63281 9.41016 7.01719 9.41016 7.49063C9.41016 7.875 9.625 8.21719 9.93008 8.31562L12.607 9.19219C13.7113 9.55313 14.4848 10.7109 14.4848 12.0094C14.4848 13.6031 13.3504 14.8922 11.9453 14.9344V15.7219C11.9453 16.1531 11.6273 16.5 11.232 16.5H10.7551C10.3598 16.5 10.0418 16.1531 10.0418 15.7219V14.925C9.37578 14.8875 8.73125 14.6391 8.19414 14.2031C7.95352 14.0109 7.92773 13.6266 8.14258 13.4016L8.84297 12.675C9.00625 12.5016 9.25117 12.4969 9.44453 12.6281C9.67656 12.7875 9.93437 12.8672 10.2094 12.8672H11.8809C12.2676 12.8672 12.5812 12.4828 12.5812 12.0094C12.5812 11.625 12.3664 11.2828 12.0613 11.1844L9.38437 10.3078C8.28008 9.94687 7.50664 8.78906 7.50664 7.49063C7.51094 5.89688 8.64102 4.60781 10.0461 4.56563ZM20.625 16.5H19.2285C18.3863 17.7188 17.3121 18.7359 16.0918 19.5H18.8332C19.0609 19.5 19.2457 19.6688 19.2457 19.875V20.625C19.2457 20.8312 19.0609 21 18.8332 21H3.1625C2.93477 21 2.75 20.8312 2.75 20.625V19.875C2.75 19.6688 2.93477 19.5 3.1625 19.5H5.90391C4.68359 18.7359 3.61367 17.7188 2.76719 16.5H1.375C0.614453 16.5 0 17.1703 0 18V22.5C0 23.3297 0.614453 24 1.375 24H20.625C21.3855 24 22 23.3297 22 22.5V18C22 17.1703 21.3855 16.5 20.625 16.5Z" fill="currentColor"/>
        </svg>

        <svg v-else width="23" height="22" class="w-8 h-full self-center" viewBox="0 0 23 22" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.68341 10.1271H0.547258C0.245035 10.1271 0 9.90257 0 9.62569V1.25564C0 0.97876 0.245035 0.754272 0.547258 0.754272H2.73629C3.03851 0.754272 3.28355 0.97876 3.28355 1.25564V4.51921C5.37102 2.39538 8.40315 1.06637 11.774 1.08881C18.0176 1.13034 23.0132 5.7525 23 11.4726C22.9867 17.1849 17.9282 21.8117 11.69 21.8117C8.76726 21.8117 6.10361 20.7959 4.09636 19.1288C3.86382 18.9357 3.85311 18.6043 4.07507 18.401L5.62412 16.9818C5.82816 16.7949 6.15597 16.7847 6.37209 16.9599C7.78748 18.1073 9.64907 18.8035 11.69 18.8035C16.1259 18.8035 19.7165 15.5147 19.7165 11.4501C19.7165 7.38625 16.1266 4.09673 11.69 4.09673C9.02232 4.09673 6.66072 5.28647 5.20164 7.11885H9.68341C9.98563 7.11885 10.2307 7.34334 10.2307 7.62022V9.62569C10.2307 9.90257 9.98563 10.1271 9.68341 10.1271Z"
                fill="currentColor"/>
        </svg>

        <div class="ml-5 leading-tight">
            <div>Type</div>
            <div>
                <span class="text-xl capitalize font-bold" v-html="frequency"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            link: {type: Object, required: true},
        },

        computed: {
            frequency() {
                if (this.link.frequency === 'open') {
                    return 'Open Amount';
                }

                if (this.link.frequency === 'single') {
                    return 'Single Payment';
                }

                return `${this.link.frequency} Recurring`;
            }
        }
    }
</script>
