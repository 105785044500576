<template>
    <div class="flex">
        <svg viewBox="0 0 25 27" class="w-8 h-full self-center" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.57143 17.5L17.4286 8.5M8.39286 9.25H8.40929M16.6071 16.75H16.6236M24 28V4C24 3.20435 23.6538 2.44129 23.0376 1.87868C22.4214 1.31607 21.5857 1 20.7143 1H4.28571C3.41429 1 2.57855 1.31607 1.96236 1.87868C1.34617 2.44129 1 3.20435 1 4V28L6.75 25L12.5 28L18.25 25L24 28ZM9.21429 9.25C9.21429 9.44891 9.12774 9.63968 8.9737 9.78033C8.81965 9.92098 8.61071 10 8.39286 10C8.175 10 7.96607 9.92098 7.81202 9.78033C7.65797 9.63968 7.57143 9.44891 7.57143 9.25C7.57143 9.05109 7.65797 8.86032 7.81202 8.71967C7.96607 8.57902 8.175 8.5 8.39286 8.5C8.61071 8.5 8.81965 8.57902 8.9737 8.71967C9.12774 8.86032 9.21429 9.05109 9.21429 9.25ZM17.4286 16.75C17.4286 16.9489 17.342 17.1397 17.188 17.2803C17.0339 17.421 16.825 17.5 16.6071 17.5C16.3893 17.5 16.1804 17.421 16.0263 17.2803C15.8723 17.1397 15.7857 16.9489 15.7857 16.75C15.7857 16.5511 15.8723 16.3603 16.0263 16.2197C16.1804 16.079 16.3893 16 16.6071 16C16.825 16 17.0339 16.079 17.188 16.2197C17.342 16.3603 17.4286 16.5511 17.4286 16.75Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div class="ml-5">
            <div class="text-lg leading-tight">
                <div>{{  link.tax.name }}</div>
                <div class="font-bold text-xl">
                    {{ `${link.currency}${link.tax.amount}` }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        link: {type: Object, required: true},
    },

    computed: {
        frequency() {
            if (this.link.frequency === 'single' || this.link.frequency === 'open') {
                return '';
            }

            if (this.link.frequency === 'daily') {
                return '/Day';
            }

            if (this.link.frequency === 'weekly') {
                return '/Week';
            }

            if (this.link.frequency === 'monthly') {
                return '/Month';
            }

            if (this.link.frequency === 'yearly') {
                return '/Year';
            }

            return `charges ${this.link.frequency}`;
        }
    }
}
</script>
