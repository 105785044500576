<template>
    <svg width="446" height="331" viewBox="0 0 446 331" fill="none" xmlns="http://www.w3.org/2000/svg" class="max-w-full px-10">
        <path d="M77.5225 187C77.5225 187 77.5225 183.257 71.6791 178.029C65.8356 172.802 62.0908 169.805 62.0908 169.805C62.0908 169.805 59.1691 178.398 64.5908 183.635C68.5803 187.314 72.9059 190.688 77.5225 193.722V187Z" fill="#92E3A9"/>
        <path d="M69.0417 210.299C69.0417 210.299 69.0417 206.611 63.1982 201.328C57.3547 196.045 53.61 193.113 53.61 193.113C53.61 193.113 50.6883 201.752 56.1408 206.943C60.1303 210.622 64.4559 213.996 69.0725 217.029L69.0417 210.299Z" fill="#92E3A9"/>
        <path d="M77.1491 193.325C77.1491 193.325 77.5914 189.637 84.011 184.953C90.4306 180.27 94.5251 177.688 94.5251 177.688C94.5251 177.688 96.4078 186.512 90.41 191.205C86.0119 194.489 81.3145 197.437 76.3672 200.019L77.1491 193.325Z" fill="#92E3A9"/>
        <path d="M66.9128 220.892C66.9128 220.892 67.7873 217.205 74.7213 213.222C81.6552 209.239 86.0378 206.98 86.0378 206.98C86.0378 206.98 86.8814 215.932 80.359 220.035C75.5982 222.884 70.581 225.373 65.3594 227.475L66.9128 220.892Z" fill="#92E3A9"/>
        <path d="M68.8359 250.949C68.8359 250.949 68.8359 247.215 74.6794 241.978C80.5229 236.741 84.2676 233.754 84.2676 233.754C84.2676 233.754 87.1893 242.356 81.7677 247.584C77.7821 251.284 73.4563 254.676 68.8359 257.726V250.949Z" fill="#92E3A9"/>
        <path d="M68.7453 258.288C68.7453 258.288 69.3317 254.6 64.3833 248.663C59.4349 242.725 56.1531 239.305 56.1531 239.305C56.1531 239.305 51.894 247.436 56.4309 253.31C59.7946 257.463 63.5423 261.355 67.6343 264.945L68.7453 258.288Z" fill="#92E3A9"/>
        <path d="M62.9844 280.029C62.9844 280.029 62.9844 276.341 68.8175 271.058C74.6507 265.775 78.416 262.834 78.416 262.834C78.416 262.834 81.3378 271.436 75.9161 276.663C71.9284 280.347 67.6027 283.724 62.9844 286.759V280.029Z" fill="#92E3A9"/>
        <path d="M66.8877 299.741C66.8877 299.741 65.859 296.127 69.9741 289.737C74.0892 283.348 76.9389 279.595 76.9389 279.595C76.9389 279.595 82.1857 287.257 78.4203 293.545C75.6057 298.011 72.3777 302.258 68.7704 306.241L66.8877 299.741Z" fill="#92E3A9"/>
        <path d="M78.0431 186.005C77.7499 189.894 77.2176 193.766 76.4485 197.603C74.1338 208.298 68.3623 215.185 66.0579 224.839C63.7534 234.492 70.3787 246.966 68.5989 259.33C66.8192 271.694 61.3975 278.627 62.9818 286.898C64.5661 295.168 72.8272 318.051 72.8272 318.051" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M77.0898 195.805L83.386 191.481" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M77.3837 190.826L74.75 186.631" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M66.4297 224.396C66.4297 224.396 71.697 220.856 77.1084 217.315" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M69.0416 217.029C69.0416 217.029 64.6487 209.322 59.6797 204.205" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M69.043 256.269C72.0493 251.302 75.8439 246.752 80.3081 242.762" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.0289 261.515C66.4258 258.144 64.5161 254.897 62.3192 251.807C59.2329 247.741 58.8008 247.197 58.8008 247.197" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M62.9844 286.898C64.316 283.919 65.9016 281.037 67.727 278.277C69.559 275.722 71.6236 273.308 73.8997 271.058" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.7874 306.25C68.4786 303.408 69.022 300.543 70.3614 297.952C72.5527 294.154 75.0423 289.166 76.0711 287.202" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29.8698 236.898C29.8698 236.898 29.3966 234.547 32.4829 230.702C35.5692 226.858 37.5033 224.608 37.5033 224.608C37.5033 224.608 40.3736 229.716 37.6062 233.524C35.5544 236.222 33.252 238.76 30.7237 241.112L29.8698 236.898Z" fill="#92E3A9"/>
        <path d="M38.0044 250.71C38.0044 250.71 37.5517 248.368 40.5866 244.514C43.6215 240.66 45.607 238.429 45.607 238.429C45.607 238.429 48.4773 243.527 45.7099 247.344C43.6558 250.038 41.3536 252.573 38.8274 254.923L38.0044 250.71Z" fill="#92E3A9"/>
        <path d="M30.8661 240.853C30.8661 240.853 30.146 238.567 25.5473 236.244C20.9487 233.92 18.0784 232.731 18.0784 232.731C18.0784 232.731 17.9652 238.456 22.2964 240.807C25.4527 242.439 28.7564 243.83 32.1727 244.966L30.8661 240.853Z" fill="#92E3A9"/>
        <path d="M40.6295 257.145C40.6295 257.145 39.6007 254.932 34.8066 253.088C30.0125 251.244 26.957 250.276 26.957 250.276C26.957 250.276 27.5126 255.974 32.1009 257.91C35.4246 259.234 38.8658 260.308 42.3887 261.119L40.6295 257.145Z" fill="#92E3A9"/>
        <path d="M43.0646 276.175C43.0646 276.175 42.6017 273.833 38.3117 271.122C34.0217 268.412 31.2954 266.9 31.2954 266.9C31.2954 266.9 30.5135 272.57 34.5464 275.327C37.4923 277.249 40.6137 278.945 43.8774 280.397L43.0646 276.175Z" fill="#92E3A9"/>
        <path d="M44.0121 280.766C44.0121 280.766 43.1891 278.507 45.5759 274.312C47.9626 270.117 49.5778 267.646 49.5778 267.646C49.5778 267.646 53.23 272.33 51.1004 276.451C49.4927 279.384 47.6138 282.19 45.4833 284.841L44.0121 280.766Z" fill="#92E3A9"/>
        <path d="M51.3382 299.676C51.3382 299.676 51.6468 297.316 55.762 294.384C59.8771 291.452 62.4902 289.774 62.4902 289.774C62.4902 289.774 63.6424 295.398 59.7845 298.349C56.9664 300.416 53.9586 302.267 50.793 303.88L51.3382 299.676Z" fill="#92E3A9"/>
        <path d="M50.2594 293.84C50.2594 293.84 49.8068 291.498 45.5065 288.788C41.2062 286.077 38.5005 284.565 38.5005 284.565C38.5005 284.565 37.7084 290.263 41.7412 293.029C44.6872 294.951 47.8085 296.647 51.0722 298.1L50.2594 293.84Z" fill="#92E3A9"/>
        <path d="M50.1986 306.582C50.1986 306.582 50.425 304.212 47.0403 300.617C43.6556 297.021 41.4437 294.937 41.4437 294.937C41.4437 294.937 39.0775 300.248 42.205 303.825C44.5119 306.353 47.0516 308.703 49.7974 310.851L50.1986 306.582Z" fill="#92E3A9"/>
        <path d="M29.3945 236.345C30.0529 238.756 30.86 241.132 31.8122 243.463C34.5487 249.917 39.0136 253.706 41.6164 259.533C44.2192 265.36 41.6164 273.805 44.1986 281.412C46.7809 289.018 51.0297 292.817 51.0709 298.155C51.112 303.493 48.9413 317.922 48.9413 317.922" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.2032 242.402L26.7383 240.3" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M30.4219 239.305L31.5638 236.419" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.3701 259.293C41.3701 259.293 37.6356 257.588 33.8086 255.891" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M38.8281 254.923C39.9236 251.828 41.368 248.84 43.1387 246.007" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M43.5785 279.531C41.0884 276.71 38.1571 274.225 34.875 272.155" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M44.8281 282.721C45.4214 280.453 46.2232 278.232 47.2252 276.083C48.6655 273.234 48.8712 272.865 48.8712 272.865" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M50.8828 302.249C52.3339 300.634 53.9416 299.137 55.6872 297.777C56.7858 297.04 57.8178 296.226 58.7735 295.343" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0923 298.155C49.8953 296.42 48.5505 294.771 47.0698 293.222C45.6171 291.815 44.0378 290.516 42.3477 289.341" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M49.798 310.851C49.6467 309.043 48.9575 307.305 47.8022 305.817C45.971 303.641 43.8002 300.755 42.9258 299.621" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M429.023 247.962H332.41V255.347H429.023V247.962Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M424.55 255.439H336.898V318.346H424.55V255.439Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M421.386 259.542H339.938V278.673H421.386V259.542Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M384.601 267.342H376.453V270.864H384.601V267.342Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M421.386 283.707H339.938V302.839H421.386V283.707Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M384.601 291.507H376.453V295.029H384.601V291.507Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M383.754 196.838H343.312V232.408H383.754V196.838Z" fill="#263238" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M366.152 246.201V231.071H360.915V246.201H353.426V247.851H360.915H366.152H373.641V246.201H366.152Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M345.848 198.129V230.343" stroke="#5C5C5C" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M352.582 198.129V230.343" stroke="#5C5C5C" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M363.539 198.129V230.343" stroke="#5C5C5C" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M376.453 198.129V230.343" stroke="#5C5C5C" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M402.395 97.5215H336.43V176.905H402.395V97.5215Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M396.078 105.119H342.746V169.307H396.078V105.119Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M323.566 76.7952H194.66V318.347H323.566V76.7952Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M253.25 88.2094H206.379V151.227H253.25V88.2094Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.518 97.2725H213.113V142.173H246.518V97.2725Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.117 97.2725L206.379 88.2094" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.512 97.2725L253.25 88.2094" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.512 142.173L253.25 151.227" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.117 142.173L206.379 151.227" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M309.586 88.2094H262.715V151.227H309.586V88.2094Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.854 97.2725H269.449V142.173H302.854V97.2725Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269.453 97.2725L262.715 88.2094" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.848 97.2725L309.586 88.2094" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.848 142.173L309.586 151.227" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269.453 142.173L262.715 151.227" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M253.25 167.473H206.379V230.49H253.25V167.473Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.518 176.527H213.113V221.427H246.518V176.527Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.117 176.527L206.379 167.473" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.512 176.527L253.25 167.473" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.512 221.427L253.25 230.49" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.117 221.427L206.379 230.49" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M309.586 167.473H262.715V230.49H309.586V167.473Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.854 176.527H269.449V221.427H302.854V176.527Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269.453 176.527L262.715 167.473" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.848 176.527L309.586 167.473" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.848 221.427L309.586 230.49" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269.453 221.427L262.715 230.49" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M253.25 247.944H206.379V310.961H253.25V247.944Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.518 256.997H213.113V301.898H246.518V256.997Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.117 256.998L206.379 247.944" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.512 256.998L253.25 247.944" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246.512 301.898L253.25 310.952" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.117 301.898L206.379 310.952" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M309.586 247.944H262.715V310.961H309.586V247.944Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.854 256.997H269.449V301.898H302.854V256.997Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269.453 256.998L262.715 247.944" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.848 256.998L309.586 247.944" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M302.848 301.898L309.586 310.952" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269.453 301.898L262.715 310.952" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M314.985 204.509C317.542 204.509 319.614 202.651 319.614 200.36C319.614 198.068 317.542 196.211 314.985 196.211C312.428 196.211 310.355 198.068 310.355 200.36C310.355 202.651 312.428 204.509 314.985 204.509Z" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M444.609 318.346H1" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M146.873 216.559H253.505C257.557 216.559 261.443 218.002 264.308 220.569C267.173 223.137 268.783 226.619 268.783 230.251V318.356H131.605V230.241C131.608 226.613 133.218 223.135 136.08 220.569C138.943 218.004 142.824 216.562 146.873 216.559Z" fill="#263238" stroke="#263238" stroke-width="1.09196" stroke-miterlimit="10"/>
        <path d="M138.449 216.559H245.041C249.092 216.559 252.978 218.002 255.843 220.569C258.708 223.137 260.318 226.619 260.318 230.251V318.356H123.141V230.241C123.142 228.442 123.539 226.66 124.309 224.998C125.08 223.337 126.208 221.827 127.63 220.556C129.052 219.286 130.739 218.278 132.595 217.593C134.452 216.907 136.441 216.556 138.449 216.559Z" fill="#787878" stroke="#263238" stroke-width="1.09196" stroke-miterlimit="10"/>
        <path d="M167.993 145.225C167.993 145.225 154.989 142.183 156.398 130.27C157.808 118.358 167.993 114.302 175.636 113.029C183.28 111.757 194.309 111.766 194.309 111.766C194.309 111.766 192.045 107.709 200.811 106.944C209.576 106.179 233.906 113.795 243.525 129.256C253.144 144.718 251.159 170.322 246.92 178.693C242.682 187.065 238.69 204.297 246.632 210.124C254.574 215.951 269.553 222.294 267.29 238.521C265.027 254.748 245.223 255.227 246.066 263.866C246.91 272.505 265.87 278.83 261.066 293.785C256.262 308.739 227.97 311.026 208.732 301.391C189.494 291.756 194.021 260.575 188.085 243.306C182.149 226.037 159.505 223.308 156.11 209.128C152.715 194.948 164.34 187.83 169.978 175.936C175.616 164.043 167.993 145.225 167.993 145.225Z" fill="#263238" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M194.307 111.766C194.307 111.766 157.94 109.572 144.926 125.67" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M236.086 121.244C236.086 121.244 258.544 136.54 259.439 159.885C260.334 183.229 249.151 191.232 247.31 201.743C245.468 212.254 254.954 218.237 254.954 218.237" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M240.129 126.472C240.129 126.472 259.439 142.146 262.587 163.905C265.735 185.663 262.587 188.457 262.587 188.457" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M102.364 261.082C102.364 261.082 90.4814 277.309 87.6522 283.643C84.8231 289.977 85.1112 295.048 92.4669 296.551C99.8227 298.054 111.139 290.263 114.781 286.685C118.423 283.108 125.244 271.98 125.244 271.98L102.364 261.082Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M179.596 206.067C179.596 206.067 150.173 205.818 142.56 208.095C134.947 210.373 98.4258 260.824 98.4258 260.824L123.888 275.788L134.917 264.881C134.917 264.881 138.003 278.83 138.003 283.394C138.003 287.958 134.32 318.429 134.32 318.429H219.184C219.184 318.429 220.603 289.986 225.356 282.131C230.109 274.276 236.673 263.627 236.673 263.627L260.715 277.567L275.767 245.085C275.767 245.085 237.578 217.988 227.949 214.18C218.32 210.373 179.596 206.067 179.596 206.067Z" fill="#92E3A9" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M134.895 264.88C134.895 264.88 135.923 256.721 137.209 249.115C138.495 241.508 143.732 232.15 143.732 232.15" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M236.736 263.617L222.086 256.14" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M233.832 263.166C233.832 263.166 220.745 261.995 215.602 260.234" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M142.633 252.673L192.765 329.106L194.782 328.073L198.537 258.353L142.633 252.673Z" fill="#263238" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M140.615 252.673L136.582 320.845L192.764 329.106L196.508 258.353L140.615 252.673Z" fill="#575757" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M161.355 274.875L172.888 276.166L170.872 319.038L150.121 315.94L161.355 274.875Z" fill="#263238" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M179.593 206.067C179.593 206.067 172.042 206.002 163.606 206.233C162.592 213.662 162.354 221.159 162.896 228.628C164.028 240.798 166.857 242.578 174.5 240.549C182.144 238.521 197.689 221.28 202.216 215.195C203.593 213.398 204.814 211.508 205.868 209.543C192.905 207.551 179.593 206.067 179.593 206.067Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M169.124 193.141C169.124 193.141 167.612 224.58 167.89 228.637C168.167 232.694 174.505 237 179.031 233.957C183.558 230.915 197.169 212.632 198.578 209.331C199.987 206.03 201.664 191.112 201.664 191.112C201.664 191.112 189.504 197.705 181.305 196.939C173.105 196.174 169.124 193.141 169.124 193.141Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M92.7472 277.816C88.5086 282.629 83.982 288.714 85.3914 293.278C86.8008 297.842 93.6216 299.87 108.59 299.612C123.559 299.354 165.173 300.377 166.017 300.626C166.86 300.875 196.561 303.392 197.693 303.392C198.824 303.392 205.049 301.105 206.746 299.077C208.444 297.049 209.832 289.193 209.832 289.193C209.832 289.193 214.071 285.395 214.359 282.352C214.403 281.423 214.193 280.499 213.749 279.658C213.304 278.818 212.638 278.088 211.808 277.53C211.743 275.901 211.46 274.285 210.964 272.717C210.11 269.951 203.886 271.196 200.491 270.689C197.096 270.182 192.014 269.168 190.028 270.938C188.043 272.708 171.644 285.395 171.644 285.395C171.644 285.395 146.182 283.615 130.061 278.295C113.94 272.976 104.887 271.952 98.3746 277.53" fill="white"/>
        <path d="M92.7472 277.816C88.5086 282.629 83.982 288.714 85.3914 293.278C86.8008 297.842 93.6216 299.87 108.59 299.612C123.559 299.354 165.173 300.377 166.017 300.626C166.86 300.875 196.561 303.392 197.693 303.392C198.824 303.392 205.049 301.105 206.746 299.077C208.444 297.049 209.832 289.193 209.832 289.193C209.832 289.193 214.071 285.395 214.359 282.352C214.403 281.423 214.193 280.499 213.749 279.658C213.304 278.818 212.638 278.088 211.808 277.53C211.743 275.901 211.46 274.285 210.964 272.717C210.11 269.951 203.886 271.196 200.491 270.689C197.096 270.182 192.014 269.168 190.028 270.938C188.043 272.708 171.644 285.395 171.644 285.395C171.644 285.395 146.182 283.615 130.061 278.295C113.94 272.976 104.887 271.952 98.3746 277.53" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M211.833 277.558L196.844 274.515" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M157.599 149.936C157.599 149.936 149.369 145.64 148.011 149.936C146.653 154.233 157.599 149.936 157.599 149.936Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M156.575 153.624C156.575 153.624 149.374 158.529 153.489 158.843C157.604 159.156 156.575 153.624 156.575 153.624Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M201.838 129.33C201.838 129.33 167.106 139.555 166.613 142.238C166.119 144.921 156.695 165.361 158.681 179.588C160.666 193.814 172.919 199.678 183.834 200.507C197.126 201.522 211.272 185.553 211.272 185.553C211.272 185.553 218.617 183.017 222.588 181.247C226.559 179.477 227.053 174.572 227.053 174.572C227.053 174.572 217.218 168.026 208.782 155.136C203.718 147.322 201.305 138.354 201.838 129.33Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M184.472 148C184.675 151.12 183.81 154.221 181.993 156.888C180.24 159.259 177.604 160.995 174.555 161.784C174.555 161.784 175.048 166.67 179.02 166.67" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M169.41 153.809C169.41 153.809 173.371 150.259 179.029 154.565" stroke="#263238" stroke-width="2.18392" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M192.32 157.902C192.32 157.902 200.242 157.137 202.217 161.959" stroke="#263238" stroke-width="2.18392" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M181.294 172.857C181.294 172.857 175.069 170.829 169.977 173.871C164.885 176.914 163.753 181.736 166.582 183.257C169.411 184.778 174.216 178.435 177.611 182.243C181.005 186.051 186.664 192.127 188.927 186.29C191.19 180.454 185.82 173.871 181.294 172.857Z" fill="#263238" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M178.359 183.073C181.785 186.844 186.826 191.712 188.925 186.29C189.138 185.731 189.283 185.153 189.357 184.566C185.828 184.234 182.001 183.709 178.359 183.073Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M186.386 176.13C185.11 174.591 183.329 173.447 181.293 172.857C181.293 172.857 175.584 171.013 170.625 173.53C175.797 174.757 181.066 175.626 186.386 176.13Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M165.084 180.307C165 180.875 165.096 181.454 165.362 181.975C165.628 182.497 166.052 182.941 166.586 183.257C168.027 184.022 169.971 182.768 171.987 181.846C169.179 181.266 166.761 180.703 165.084 180.307Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M183.289 145.705C183.289 145.705 182.97 143.962 178.772 143.962C174.575 143.962 167.785 148.572 167.785 148.572" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M192.672 147.438C192.672 147.438 195.902 144.543 199.133 148.019C201.239 150.401 202.969 153.032 204.276 155.837" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M212.122 161.701C190.405 149.439 201.835 129.33 201.835 129.33C201.835 129.33 180.23 145.926 166.609 142.238" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M207.032 174.885C207.032 174.885 203.945 178.186 203.945 179.956C203.945 181.726 210.169 189.084 212.721 186.041C215.272 182.999 211.867 180.722 210.735 177.679C209.604 174.636 207.032 174.885 207.032 174.885Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M248.05 173.871C248.05 173.871 228.503 165.509 222.022 162.466C215.541 159.424 212.125 161.701 212.125 161.701C212.125 161.701 200.521 170.58 201.086 172.101C201.652 173.622 208.164 178.435 208.164 178.435C208.164 178.435 212.125 183.506 216.93 184.778C221.734 186.051 228.812 187.812 230.232 185.783C231.651 183.755 230.232 180.722 230.232 180.722C230.232 180.722 234.182 183.764 236.734 184.271C239.285 184.778 244.655 184.271 244.655 184.271C244.655 184.271 255.406 246.376 255.406 255.753C255.406 265.129 259.933 275.022 267.854 277.309C275.776 279.595 284.819 274.266 286.516 266.66C288.214 259.054 287.37 250.691 282.278 241.305C277.185 231.919 258.235 188.844 254.552 182.75C250.869 176.656 250.313 175.392 248.05 173.871Z" fill="white" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M219.48 185.534C219.48 185.534 223.153 176.665 224.573 174.636" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M213.258 182.75C213.258 182.75 218.062 171.326 219.719 169.307" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M208.164 178.435C208.164 178.435 211.837 169.566 213.822 166.523" stroke="#263238" stroke-width="1.09196" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M108.413 104.325L98.5777 101.384L106.232 81.8012H96.9729L100.502 69.1608C96.9153 64.4893 91.4569 61.2372 85.2626 60.0815C79.0683 58.9258 72.6153 59.9555 67.2467 62.9563C61.8782 65.9571 58.0078 70.6977 56.4412 76.1915C54.8745 81.6853 55.7323 87.5089 58.8361 92.4502C67.2618 108.336 90.08 125.485 98.9584 131.764L108.413 104.325Z" fill="#263238"/>
        <path d="M124.81 59.5633C118.552 59.5606 112.529 61.705 107.979 65.5562L104.039 76.9059L114.975 76.4172L104.595 98.4896L115.52 100.933L105.521 132.197C113.895 126.297 137.557 108.714 146.178 92.4506C148.294 89.0989 149.393 85.3088 149.366 81.4579C149.338 77.607 148.185 73.8298 146.021 70.5027C143.857 67.1756 140.759 64.4148 137.033 62.4954C133.308 60.576 129.087 59.5651 124.79 59.5633H124.81Z" fill="#263238"/>
    </svg>
</template>

<script>
export default {

}
</script>
